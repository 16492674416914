import { useState, useEffect } from 'react'

export default function usePreviousRoute() {
  const [previousRoute, setPreviousRoute] = useState(
    typeof window !== 'undefined' ? window.previousPath : null
  )

  useEffect(() => {
    const previousPath = typeof window !== 'undefined' ? window.previousPath : null
    setPreviousRoute(previousPath)
  }, [previousRoute])

  return previousRoute
}
