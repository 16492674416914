import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { TurSystemIcon, LibraryIcon } from '../img/icons'

const SlidingMenu = () => {
  const { site, allMarkdownRemark } = useStaticQuery(graphql`
    query SlidingMenuMetaDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(filter: { frontmatter: { dataType: { eq: "settings" } } }) {
        edges {
          node {
            id
            frontmatter {
              showMenuOnMobile
              showBlog
            }
          }
        }
      }
    }
  `)

  const { edges: settings } = allMarkdownRemark
  const { showMenuOnMobile, showBlog } = settings[0].node.frontmatter

  return showMenuOnMobile ? (
    <div className="sliding-menu fixed bg-surface-0 text-high-on-surface-0 top-0 z-10 w-72 -left-72 transition duration-300 ease-in-out shadow-xs">
      <div className="bg-surface-24 min-h-screen w-full text-sm p-2">
        <div className="font-bold text-xl tracking-normal text-medium-on-surface-0 py-6 px-6 border-solid border-b border-disabled-on-surface-">
          <TurSystemIcon className="inline-block fill-current h-6 w-6" />
          <span className="ml-4">{site.siteMetadata.title}</span>
        </div>
        <div className="p-6">
          {[
            showBlog && {
              route: `/blog`,
              title: `Blog`,
            },
          ].map(link => (
            <Link
              className="block font-medium md:inline-block mt-4 md:mt-0 md:ml-6 no-underline"
              key={link.title}
              to={link.route}
            >
              <LibraryIcon />
              <span className="ml-4">{link.title}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : null
}

export default SlidingMenu
