import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const MenuItems = ({ showBlog }) => {
  return (
    <>
      {[
        showBlog && {
          route: `/blog`,
          title: `Blog`,
        },
      ].map(link => (
        <Link
          className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline"
          key={link.title}
          to={link.route}
        >
          {link.title}
        </Link>
      ))}
    </>
  )
}

MenuItems.propTypes = {
  showBlog: PropTypes.bool.isRequired,
}

export default MenuItems
