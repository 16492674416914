import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { usePreviousRoute } from '../hooks'
import { MenuIcon, NavigateBeforeIcon } from '../img/icons'

const ToggleMenuIcon = ({ location: { pathname } }) => {
  const routes = ['blog', 'tags']
  const [showNavigateBeforeIcon, setIcon] = useState(false)
  const [previousRoute, setPreviousRoute] = useState(`/${routes[0]}`)
  const referrer = usePreviousRoute()

  // RULES:
  // 1. Previous route for blog posts is "/blog"
  // 2. Previous route for "/tags" pages is either previous route which is a blog or the "/blog"

  useEffect(() => {
    function isBlogPost(parts) {
      return parts[0] === routes[0] && parts.length > 1
    }

    function isTagsPage(parts) {
      return parts[0] === routes[1]
    }

    function isPrevRouteAblogPost(previousRoute) {
      const parts = previousRoute.replace(/^\/+/g, '').split('/')
      return isBlogPost(parts)
    }
    const parts = pathname.replace(/^\/+/g, '').split('/')
    let prevRoute = null
    if (parts) {
      if (isBlogPost(parts)) {
        prevRoute = `/${routes[0]}`
      } else if (isTagsPage(parts)) {
        prevRoute = isPrevRouteAblogPost(referrer || previousRoute)
          ? referrer || previousRoute
          : `/${routes[0]}`
      }
    }

    setPreviousRoute(prevRoute)
    setIcon(prevRoute && isMobile) // show on mobile only
  }, [referrer])

  return showNavigateBeforeIcon ? (
    <Link className="flex items-center px-3 py-2" to={previousRoute} title="Back to previous page">
      <NavigateBeforeIcon />
    </Link>
  ) : (
    <button
      className="flex items-center px-3 py-2"
      onClick={() => {
        window.__toggleSlidingMenu()
      }}
    >
      <MenuIcon />
    </button>
  )
}

ToggleMenuIcon.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ToggleMenuIcon
